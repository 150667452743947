import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Section, Grid } from './introWithImage.css'

const Intro = ({ data, small, topMargin, headingLevel }) => {
  const image = getImage(data.image)
  return (
    <Section small={small} topMargin={topMargin}>
      <Fade bottom delay={150} duration={1000} distance="10px">
        {headingLevel === 'h1' ? <h1>{data.heading}</h1> : <h2>{data.heading}</h2>}
      </Fade>

      {data.intro.map((item, i) => (
        <Fade key={i} bottom delay={150} duration={1250} distance="20px">
          <p dangerouslySetInnerHTML={{ __html: item }} />
        </Fade>
      ))}

      <Grid>
        <Fade bottom delay={150} duration={1250} distance="20px">
          <GatsbyImage image={image} alt={data.imageAlt} />
        </Fade>
        <div>
          {data.copy.map((item, i) => (
            <Fade key={i} bottom delay={150} duration={1250} distance="20px">
              <p>{item}</p>
            </Fade>
          ))}
        </div>
      </Grid>
    </Section>
  )
}
Intro.propTypes = {
  data: PropTypes.object.isRequired,
  headingLevel: PropTypes.string,
  small: PropTypes.bool,
  topMargin: PropTypes.bool
}

export default Intro
