import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Section = styled.section`
  max-width: ${(props) => (props.small ? '720px' : '1128px')};
  margin: 0 auto var(--xl);
  padding: 0 var(--md);
  text-align: center;
  margin-top: ${(props) => (props.topMargin ? 'var(--xl)' : null)};

  h1,
  h2 {
    font-size: var(--titleLevel1);
    margin-bottom: var(--sm);
  }
  p {
    margin-bottom: var(--lg);
  }
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--md) var(--lg);

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
    text-align: left;
  `}

  p {
    margin-bottom: var(--md);
  }
`
